<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-tabs class="md-primary" md-sync-route>
        <md-tab
          id="tab-items-list"
          md-label="Список предметов"
          to="/items/list"
          exact
        />
        <md-tab
          id="tab-shop-items-list"
          md-label="Предметы магазина"
          to="/items/shop"
        />
      </md-tabs>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.md-layout {
  position: relative;
  z-index: 2;
}
</style>
